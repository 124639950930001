body {
  font-family: "arial";
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  display: inline-block;
  padding: 8px 12px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  color: black;
  text-decoration: none;
}

.pagination a:hover {
  background-color: #f0f0f0;
  color: black;
}
